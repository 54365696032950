/*
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-05 14:34:57
 * @LastEditTime: 2021-02-06 16:27:31
 * @LastEditors: fely
 */
import { request } from './index.js';
// import { getTocken } from '@/utils/auth.js'
// 推荐职位列表
export function getPosRec(params) {
  return request({
    url: '/api/pPost/getpostindex',
    method: 'GET',
    params
  })
}
// 通过职位id获取职位
export function getPostById(id) {
  return request({
    url: '/api/pPost/getPPostbyidopen',
    method: 'GET',
    params: {
      id
    }
  })
}

// 通过公司id获取公司信息
export function getComById(id){
  return request({
    url: '/api/sysCompany/getSysCompanybyidopen',
    method: 'GET',
    params: {
      id
    }
  })
}