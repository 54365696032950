<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-05 16:11:22
 * @LastEditTime: 2021-02-06 09:39:52
 * @LastEditors: fely
-->
<template>
  <div class="pos-detail-page">
    <div class="header">
      <top-header :white="true"></top-header>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="pos-info">
          <div class="info-wrapper">
            <div class="pos-status">{{posObj.delflag==0?'招聘中':'该职位已关闭'}}</div>
            <div class="pos-name">{{posObj.title}}</div>
            <div class="pos-salary">{{posObj.lowsalarie}}-{{posObj.highsalarie}}K</div>
            <div class="pos-other">
              <span>{{posObj.address.split(',')[1]}} · {{posObj.address.split(',')[2]}}</span>
              <span class="left">{{posObj.workyear=='不限'?'经验不限':posObj.workyear}}</span>
              <span class="left">{{posObj.edutype=='不限'?'学历不限':posObj.edutype}}</span>
            </div>
          </div>
        </div>
        <div class="pos-intro" v-if="posObj.delflag==0">
          <div class="pos-part">
            
            <div class="intro-box">
              <div class="sub-title">职位描述</div>
              <div class="user-box">
                <div class="user-img">
                  <el-image fit="cover" :src="posObj.headImage">
                    <div slot="placeholder" class="image-slot">
                      <img src="~@/assets/images/userImg.png">
                    </div>
                    <div slot="error" class="image-slot">
                      <img src="~@/assets/images/userImg.png">
                    </div>
                  </el-image>
                </div>
                <div class="user-info">
                  <div>{{posObj.foreignName}} <span class="left" style="font-size:12px">{{posObj.phone}}</span></div>
                  <div>{{posObj.jbxxRealname}} / {{posObj.userfunction}}</div>
                </div>
              </div>
              <div class="seprate"></div>
              <div class="benefits">
                <div class="bene" v-for="(item,index) in skills" :key="index">
                  {{item}}
                </div>
              </div>
              <div class="text-box">
                {{posObj.description}}
              </div>
            </div>
            
            <div class="addr-box">
              <div class="sub-title">工作地址</div>
              <p class="text-addr">{{posObj.address.replace(/,/g,' ')}}</p>
              <el-image :src="mapSrc">
                <div slot="placeholder" class="image-slot">
                  地图加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  地图加载失败<span class="dot">...</span>
                </div>
              </el-image>
            </div>
          </div>
          <div class="com-part">
            <div class="com-info">
              <div class="sub-title">公司信息</div>
              <div class="com-box">
                <div class="com-img">
                  <el-image fit="cover" :src="posObj.jbxxLogo">
                    <div slot="placeholder" class="image-slot">
                      <img src="~@/assets/images/comImg.png">
                    </div>
                    <div slot="error" class="image-slot">
                      <img src="~@/assets/images/comImg.png">
                    </div>
                  </el-image>
                </div>
                <div class="com-text">
                  <div>{{posObj.jbxxRealname}}</div>
                  <div>
                    <span>{{posObj.financingstage}}</span>
                    <span class="left">{{posObj.companysize}}</span>
                    <span class="left">{{posObj.industryname}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="gsxx-box">
              <div class="sub-title">工商信息</div>
              <div class="gsxx-text">
                <p>
                  <img src="~@/assets/images/fr.png">
                  <span>企业法人：{{comGSXX.frName}}</span>
                </p>
                <p>
                  <img src="~@/assets/images/cap.png">
                  <span>注册资本：{{comGSXX.regCapital}}</span>
                </p>
                <p>
                  <img src="~@/assets/images/time.png">
                  <span>成立时间：{{comGSXX.opfrom}}</span>
                </p>
                <p>
                  <img src="~@/assets/images/sour.png">
                  <span>数据来源：翔云</span>
                </p>
              </div>
            </div>
            <div class="dwon-box">
              <div class="sub-title">下载APP</div>
              <div class="dwonlown-wrap">
                <div class="dwon-left">
                  <img src="~@/assets/images/download.png" style="height: 96px; width: 96px;">
                  <p class="dwon-label">下载亿慧小秘书APP</p>
                </div>
                <div class="dwon-right">
                  <img src="~@/assets/images/appDes.png" style="height: 150px; width: 150px;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" style="margin-top: 30px">
        <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
        <div style="margin-left: 20px;">
          <a target="_blank" href="http://www.beian.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="~@/assets/images/police.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from './topHeader'
import {getPostById,getComById} from '@/api/posList.js'
export default {
  name: '',
  components: {
    TopHeader
  },
  data() {
    return {
      posId: '',
      comId: '',
      mapSrc: '',
      posObj: {},
      comGSXX: {},
      skills: []
    }
  },
  created() {
    this.posId = this.$route.query.id
    this.init()
  },
  methods: {
    init(){
      getPostById(this.posId).then(res => {
        this.companyId = res.data.companyId
        this.getCom(this.companyId)
        this.posObj = res.data
        this.skills = res.data.posKeywords.split(/,/)
        const addr = res.data.addressLongitude+','+res.data.addressLatitude
        this.mapSrc = "https://restapi.amap.com/v3/staticmap?location="+addr+"&zoom=18&size=640*230&markers=mid,,A:"+addr+"&key=c7be8fc256c59994d86c81f55a9dbfd0"
      })
    },
    getCom(id){
      getComById(id).then(res => {
        this.comGSXX = res.data.sysCompanyGsxx
      })
    }
  },
}
</script>

<style scoped lang="less">
.pos-detail-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    position: relative;
    height: 60px;
    width: 100%;
    background-color: #fff;
  }
  .content{
    flex: 1;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .left{
        margin-left: 24px;
      }
      .pos-info{
        // padding: 50px 150px;
        width: 100%;
        height: 240px;
        box-sizing: border-box;
        background-color: #F7F9FC;
        display: flex;
        justify-content: center;
        align-items: center;
        .info-wrapper{
          width: 980px;
        }
        .pos-status{
          font-size: 14px;
          color: #666;

        }
        .pos-name{
          margin-top: 16px;
          font-size: 24px;
          font-weight: 600;
          color: #333;
        }
        .pos-salary{
          margin-top: 20px;
          font-size: 28px;
          color: #FF5A5F;
        }
        .pos-other{
          margin-top: 5px;
          display: flex;
          color: #666;
          font-size: 16px;
        }
      }
      .pos-intro{
        width: 980px;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .sub-title{
          font-size: 18px;
          font-weight: 600;
          line-height: 31px;
          margin-bottom: 24px;
          position: relative;
          z-index: 20;
          &:after{
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            bottom: 8px;
            left: 0;
            width: 70px;
            height: 5px;
            background-color: #4E90FF;
          }
        }
        .pos-part{
          padding-right: 30px;
          width: 660px;
          border-right: 2px solid #F5F5F5;
          box-sizing: border-box;
          .intro-box{
            .user-box{
              display: flex;
              .user-img{
                width: 60px;
                height: 60px;
                border-radius: 9px;
                overflow: hidden;
                .image-slot{
                  width: 100%;
                  height: 100%;
                }
              }
              .user-info{
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 16px;
              }
            }
            .seprate{
              margin: 25px 0;
              height: 1px;
              width: 100%;
              background-color: #f5f5f5;
            }
            .benefits{
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 25px;
              .bene{
                flex-shrink: 0;
                background-color: #fff;
                border: 1px solid #eee;
                padding: 6px 10px;
                color: #999;
                font-size: 12px;
                margin-right: 12px;
                margin-bottom: 10px;
              }
            }
          }
          .addr-box{
            margin-top: 50px;
            .text-addr{
              font-size: 14px;
              margin-bottom: 10px;
            }
          }
        }
        .com-part{
          width: 350px;
          padding-left: 20px;
          box-sizing: border-box;
          .com-info{
            .com-box{
              display: flex;
              .com-img{
                width: 60px;
                height: 60px;
                border-radius: 6px;
                overflow: hidden;
                .image-slot{
                  width: 100%;
                  height: 100%;
                }
              }
              .com-text{
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }
            }
          }
          .gsxx-box{
            margin-top: 50px;
            .gsxx-text{
              font-size: 14px;
              p{
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                
                img{
                  margin-right: 10px;
                  width: 14px;
                  height: 14px;
                }
              }
              p:last-child{
                margin-bottom: 0
              }
            }
          }
          .dwon-box{
            margin-top: 50px;
            .dwonlown-wrap{
              padding: 20px 16px;
              box-sizing: border-box;
              width: 300px;
              height: 156px;
              background-color: #f6faff;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .dwon-label{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
   
    
  }
}
.footer{
  height: 27px;
  width: 100%;
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex;
  justify-content: center;
  padding-bottom: 27px;
  font-size: 13px;
  box-sizing: border-box;
}
a.beian,.footer{
  color: #939295;
}
a.beian:hover{
  text-decoration:underline;
}
</style>
